<template>
  <div class="indexBox">
    <div style="display: flex; padding: 0px 0; align-items: center">
      <el-select
        v-if="false"
        clearable
        v-model="form2.Year"
        style="width: 120px; margin-right: 10px"
        placeholder="选择岁"
      >
        <el-option
          v-for="item in YearData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-if="false"
        clearable
        v-model="form2.Month"
        style="width: 120px; margin-right: 10px"
        placeholder="选择月"
      >
        <el-option
          v-for="item in MonthData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-if="false"
        clearable
        v-model="form2.Week"
        style="width: 120px; margin-right: 10px"
        placeholder="选择周"
      >
        <el-option
          v-for="item in WeekData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <!-- <span style="margin-right: 10px">至</span>
      <el-select
        v-model="form2.endYear"
        style="width: 120px; margin-right: 10px"
        placeholder="选择岁"
      >
        <el-option
          v-for="item in YearData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="form2.endMonth"
        style="width: 120px; margin-right: 10px"
        placeholder="选择月"
      >
        <el-option
          v-for="item in MonthData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="form2.endWeek"
        style="width: 120px; margin-right: 10px"
        placeholder="选择周"
      > 

        <el-option
          v-for="item in WeekData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>-->

      <span>门店: </span>
      <el-select
        clearable
        v-model="form2.StroeId"
        style="margin-left: 10px"
        placeholder="请选择门店"
      >
        <el-option
          v-for="item in AllDoorData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>

      <!-- <span style="margin-left: 20px;">教师: </span>

          <el-input style="width: 200px;margin-left: 10px" v-model="form2.TeachId" placeholder="请输入教师" /> -->

      <el-select
        clearable
        filterable
        v-model="form2.type"
        style="margin-left: 20px"
        placeholder="请选择分类名称"
      >
        <el-option
          v-for="item in PlanTypeData"
          :key="item.Id"
          :label="item.label"
          :value="item.Id"
        >
        </el-option>
      </el-select>

      <div
        style="
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #01b37c;
          color: #fff;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          cursor: pointer;
        "
        @click="TeacherSearchAllClassLimitPageFn"
      >
        搜索
      </div>
      <div
        style="
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #fff;
          color: #01b37c;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          border: 1px solid #01b37c;
          box-sizing: border-box;
          cursor: pointer;
        "
        @click="resettingFn"
      >
        重置
      </div>
    </div>

    <div style="width: 100%; margin-top: 20px">
      <el-table
        :data="MineWeekData"
        :header-cell-style="getRowClass"
        style="width: 100%; background-color: #fff"
        :row-style="{ height: '60px' }"
      >
        <el-table-column label="序号" prop="CardId">
          <template slot-scope="scope">
            <span>{{ (pageIndex - 1) * pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column label="课程名称" prop="WorkName" /> -->
        <!-- <el-table-column label="课程分类" prop="WorkType" /> -->
        <el-table-column label="课程分类" prop="WorkTypeName">
          <!-- <template slot-scope="scope">
            <span v-if="scope.row.WorkType == 1">基础课</span>
            <span v-if="scope.row.WorkType == 2">专项课</span>
            <span v-if="scope.row.WorkType == 3">试听课</span>
          </template> -->
        </el-table-column>
        <el-table-column label="门店" prop="StoreName" />
        <el-table-column label="课室" prop="StoreRoomName" />
        <el-table-column label="教师名称" prop="TeacherName" />
        <el-table-column label="课程教案" prop="PlanDetailInfo.FileName" />
        <!-- <el-table-column label="课室" prop="CardNum" /> -->
        <el-table-column label="上课时间" prop="WorkTime" />
        <el-table-column label="课程状态" prop="ClassStatus">
          <template slot-scope="scope">
            <span v-if="scope.row.ClassStatus == 0">未开课</span>
            <span v-if="scope.row.ClassStatus == 1">已开课</span>
            <span v-if="scope.row.ClassStatus == 2">已结束</span>
          </template>
        </el-table-column>
        <el-table-column label="报名学生" prop="StuNum" />
        <el-table-column v-if="false" label="操作">
          <template #default="scope">
            <!-- <el-popconfirm
                style="margin-left: 10px"
                confirm-button-text='删除'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                title="确认删除吗？"
                @confirm="deleteKeys"
            > -->
            <!-- <span
              v-if="scope.row.Status == 0"
              @click="UpdateExamineTrueFn(scope.row)"
              style="font-size: 14px; color: #007aff; cursor: pointer"
              >审核通过</span
            > -->
            <span
              @click="openDialogVisible1(scope.row)"
              style="
                font-size: 14px;
                color: #007aff;
                cursor: pointer;
                margin-left: 10px;
              "
              >修改人数</span
            >

            <!-- <span
              v-if="scope.row.Status == 2"
              @click="openDialogVisible3(scope.row)"
              style="
                font-size: 14px;
                color: #ee6e6e;
                cursor: pointer;
                margin-left: 0px;
              "
              >驳回理由</span
            > -->

            <!-- <el-button slot="reference" type="danger" @click="handleDelete(scope)">删除</el-button> -->
            <!-- </el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 10px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="AllCount"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 修改人数 -->
    <el-dialog title="学生人数" :visible.sync="dialogVisible1" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 60px">
              旧人数
            </div>
            <el-input
              disabled
              v-model="form3.oldNum"
              style="flex: 1"
              placeholder="请输入"
            />
          </div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 60px">
              新人数
            </div>
            <el-input
              v-model="form3.newNum"
              style="flex: 1"
              placeholder="请输入"
            />
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            @click="offDialogVisible1"
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="UpdateClassNumFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 驳回人数 -->
    <el-dialog title="驳回理由" :visible.sync="dialogVisible2" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div style="font-size: 24px; color: #333">
          课程数量本周已满，人数不够
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  GenerateCamilo,
  DeleteCARDSClose,
  GetAllWorksForTeacher,
  GetAllStore,
  GetAllClassByTeacher,
  TeacherSearchAllClassLimitPage,
  UpdateClassNum,
  GetAllTeachPlan,
} from "@/request/api";

export default {
  inject: ["reload"],
  name: "Keys",
  data() {
    return {
      tableData: [],
      login: true,
      dialogVisible7: false,
      SetCard: {},
      Type: "天数",
      cardId: "",
      selectKeys: "",
      allKey: [],
      showCopy: false,
      allTableData: [],

      dialogVisible1: false,
      dialogVisible2: false,
      MineWeekData: [],
      options: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "哈哈",
          value: 1,
        },
      ],
      value: "",
      form: {
        money: "",
        sunday: "",
      },
      form3: {
        oldNum: "",
        newNum: "",
        id: "",
      },
      pageIndex: 1,
      pageSize: 10,
      AllCount: 0,
      form2: {
        Year: "",
        Month: "",
        Week: "",
        // endYear: "",
        // endMonth: "",
        // endWeek: "",
        type: "",
        StroeId: "",
        TeachId: "",
      },
      WeekData: [
        {
          Id: 1,
          Name: "第一周",
        },
        {
          Id: 2,
          Name: "第二周",
        },
        {
          Id: 3,
          Name: "第三周",
        },
        {
          Id: 4,
          Name: "第四周",
        },
      ],
      YearData: [],
      MonthData: [],
      WorkTypeData: [
        {
          Id: 1,
          Name: "基础课",
        },
        {
          Id: 2,
          Name: "专项课",
        },
        {
          Id: 3,
          Name: "试听课",
        },
      ],
      searchCheck: false, //
      AllDoorData: [],
      PlanTypeData: [],
    };
  },

  watch: {
    "form2.Year"(e) {
      if (e !== "") {
        // if(this.form2.Month!==''&&this.form2.Week!==''){
        this.TeacherSearchAllClassLimitPageFn();
        // }
      }
    },
    "form2.Month"(e) {
      if (e !== "") {
        // if(this.form2.Year!==''&&this.form2.Week!==''){
        this.TeacherSearchAllClassLimitPageFn();
        // }
      }
    },
    "form2.Week"(e) {
      if (e !== "") {
        // if(this.form2.Year!==''&&this.form2.Month!==''){
        this.TeacherSearchAllClassLimitPageFn();
        // }
      }
    },
    "form2.type"(e) {
      // if(e!=''){
      this.TeacherSearchAllClassLimitPageFn();
      // }
    },
    "form2.StroeId"(e) {
      // if(e!=''){
      this.TeacherSearchAllClassLimitPageFn();
      // }
    },
  },
  methods: {
    //教案

    //获取所有分类
    GetAllTeachPlanFn() {
      let that = this;
      GetAllTeachPlan().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有教案分类==>", res.Data);
          // that.teachData = res.Data;
          that.PlanTypeData = res.Data;
        }
      });
    },

    //  // 获取所有门店
    GetAllStoreFn() {
      let that = this;
      GetAllStore().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有门店==>", res.Data);
          that.AllDoorData = res.Data;
        } else {
          this.$message.error(res.Message);
        }
      });
    },

    // 修改课程学生人数
    openDialogVisible1(row) {
      this.form3.oldNum = row.StuNum;
      this.form3.id = row.Id;
      this.dialogVisible1 = true;
    },
    offDialogVisible1() {
      this.dialogVisible1 = false;
      this.form3.oldNum = "";
      this.form3.id = "";
    },

    //修改课程学生人数
    UpdateClassNumFn() {
      let that = this;
      let data = {
        id: that.SetCard.ClassId,
        num: that.form.newNum,
      };
      UpdateClassNum({
        id: that.form3.id,
        num: that.form3.newNum,
      }).then((res) => {
        console.log("修改课程学生人数==>", res);
        if (res.Code == 2000) {
          that.$message({
            message: "修改成功",
            type: "success",
          });
          that.dialogVisible1 = false;
          that.form3.oldNum = "";
          that.form3.id = "";
          that.GetAllClassByTeacherFn();
        } else {
          that.$message({
            message: res.Message,
            type: "error",
          });
        }
      });
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      // this.GetAllClassByTeacherFn(); //
      // if (this.searchCheck == true) {
      this.TeacherSearchAllClassLimitPageFn();
      // } else {
      // this.GetAllClassLimitPageFn();
      // }
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageIndex = val;
      // this.GetAllClassByTeacherFn(); //
      // if (this.searchCheck == true) {
      this.TeacherSearchAllClassLimitPageFn();
      // } else {
      // this.GetAllClassLimitPageFn();
      // }
    },

    //列表搜索
    TeacherSearchAllClassLimitPageFn() {
      let that = this;
      console.log("课程列表搜索==>", that.form2);

      // if(that.form2.Year===''){
      //   that.$message({
      //     message: "请选择年",
      //     type: "error",
      //   });
      //   return;
      // }
      // if(that.form2.Month===''){
      //   that.$message({
      //     message: "请选择月",
      //     type: "error",
      //   });
      //   return;
      // }
      // if(that.form2.Week===''){
      //   that.$message({
      //     message: "请选择周",
      //     type: "error",
      //   });
      //   return;
      // }

      TeacherSearchAllClassLimitPage({
        Year: that.form2.Year === "" ? "-1" : that.form2.Year,
        Month: that.form2.Month == "" ? "-1" : that.form2.Month,
        Week: that.form2.Week == "" ? "-1" : that.form2.Week,
        type: that.form2.type == "" ? "-1" : that.form2.type,
        //  TeachName:that.form2.TeachId,
        storeId: that.form2.StroeId == "" ? -1 : that.form2.StroeId,
        pageindex: that.pageIndex,
        pagesize: that.pageSize,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("课程列表搜索==>", res.Data.Data);
          console.log("课程列表搜索数量==>", res.Data.AllCount);
          that.searchCheck = true;

          for (let i = 0; i < res.Data.Data.length; i++) {
            const element = res.Data.Data[i];
            element.WorkTime =
              element.WorkTime.split(" ")[0] +
              " " +
              String(
                String(
                  element.StartHour < 10
                    ? "0" + element.StartHour
                    : element.StartHour
                ) +
                  ":" +
                  String(
                    element.StartMinute < 10
                      ? "0" + element.StartMinute
                      : element.StartMinute
                  ) +
                  "-" +
                  String(
                    element.EndHour < 10
                      ? "0" + element.EndHour
                      : element.EndHour
                  ) +
                  ":" +
                  String(
                    element.EndMinute < 10
                      ? "0" + element.EndMinute
                      : element.EndMinute
                  )
              );
          }

          that.MineWeekData = res.Data.Data;
          if (that.pageIndex == 1) {
            that.AllCount = res.Data.AllCount;
          }
        } else {
          that.$message.error(res.Message);
        }
      });
    },

    //搜索重置
    resettingFn() {
      this.form2 = {
        startYear: "",
        startMonth: "",
        startWeek: "",
        endYear: "",
        endMonth: "",
        endWeek: "",
        type: "",
      };
      this.searchCheck == false;
      this.pageIndex = 1;
      this.pageSize = 10;
      this.GetAllClassByTeacherFn();
    },

    // 表头
    getRowClass({ row, column, rowIndex, columnIndex }) {
      // return "background:#50a0ff;color:#fff;font-size:16px;color:#fff";
      return "background:#F8FCFE;color:#fff;font-size:16px;color:#333";
    },

    //打开修改人数
    openEditPeoPleNum() {
      this.dialogVisible1 = true;
    },
    offEditPeoPleNum() {
      this.dialogVisible1 = false;
    },
    //打开驳回理由
    openRejectTc() {
      this.dialogVisible2 = true;
    },

    // 教师获取自己的课程
    // GetAllWorksForTeacherFn(){
    //   let that = this;
    //   GetAllWorksForTeacher().then(res=>{
    //     console.log("res==>",res);
    //     if(res.Code==2000){
    //       console.log("教师获取自己的课程==>",res);
    //       that.tableData = res.Data;
    //       this.GetAllStoreFn(res.Data)
    //     }
    //   })
    // },

    //获取自己要上的课程
    GetAllClassByTeacherFn() {
      let that = this;

      let monday = new Date();
      let sunday = new Date();
      const nowDay = new Date().getDay() || 7;
      monday.setDate(monday.getDate() - nowDay + 1);
      sunday.setDate(sunday.getDate() - nowDay + 7);
      console.log("monday==>", monday.toLocaleDateString());
      console.log("sunday==>", sunday.toLocaleDateString());
      this.form.monday = monday.toLocaleDateString();
      this.form.sunday = sunday.toLocaleDateString();

      GetAllClassByTeacher({
        StartTime: that.form.monday,
        EndTime: that.form.sunday,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取自己一周要上的课程==>", res.Data);
          that.searchCheck = false;
          // if (that.pageIndex == 1) {
          that.AllCount = res.Data.length;
          // }
          //截取res.Data.WorkTime的年月日
          for (let i = 0; i < res.Data.length; i++) {
            const element = res.Data[i];
            element.WorkTime =
              element.WorkTime.split(" ")[0] +
              " " +
              String(
                String(
                  element.StartHour < 10
                    ? "0" + element.StartHour
                    : element.StartHour
                ) +
                  ":" +
                  String(
                    element.StartMinute < 10
                      ? "0" + element.StartMinute
                      : element.StartMinute
                  ) +
                  "-" +
                  String(
                    element.EndHour < 10
                      ? "0" + element.EndHour
                      : element.EndHour
                  ) +
                  ":" +
                  String(
                    element.EndMinute < 10
                      ? "0" + element.EndMinute
                      : element.EndMinute
                  )
              );
          }
          that.MineWeekData = res.Data.slice(
            (that.pageIndex - 1) * that.pageSize,
            that.pageIndex * that.pageSize
          );

          console.log("MineWeekData==>", that.MineWeekData);
        }
      });
    },

    // //获取所有门店
    // GetAllStoreFn(row) {
    //   let that = this;
    //   GetAllStore().then((res) => {
    //     console.log(res);
    //     if (res.Code == 2000) {
    //       console.log("获取所有门店==>", res.Data);
    //       that.storeData = res.Data;
    //       for (let i = 0; i < res.Data.length; i++) {
    //         const element = res.Data[i];
    //         for (let j = 0; j < row.length; j++) {
    //           const element2 = row[j];
    //           if (element.Id == element2.StoreId) {
    //             element2.StoreName = element.Name;
    //           }
    //         }
    //       }
    //       console.log("row==>", row);
    //     }
    //   });
    // },

    select() {
      let table = this.TableData;
      let newTable = [];
      const that = this;
      if (this.selectKeys === "") {
        this.$message({
          message: "请输入需要搜索的内容",
          type: "error",
        });
      } else {
        table.forEach(function (item) {
          if (!(item.CardId.indexOf(that.selectKeys) === -1)) {
            newTable.push(item);
          }
        });
      }
      this.TableData = newTable;
    },
    // handleCurrentChange(val) {
    //   let a = this.allTableData;
    //   let b = a.slice((val - 1) * 10, val * 10);
    //   this.TableData = b;
    // },
    handleDelete(scope) {
      this.cardId = scope.row.CardId;
    },
    lost() {
      if (this.selectKeys === "") {
        this.reload();
      }
    },
    deleteKeys() {
      DeleteCARDSClose({ CardId: this.cardId }).then((res) => {
        if (res.Code === 2000) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
        } else {
          this.$message({
            message: "删除失败",
            type: "error",
          });
        }
        this.reload();
      });
    },
    copy(e) {
      let a = "";
      for (let i = 0; i < this.allKey.length; i++) {
        a += this.allKey[i] + "\n";
      }
      console.log(a);
      this.$message({
        message: "复制成功",
        type: "success",
      });
      // const input = document.createElement('input')
      // document.body.appendChild(input)
      // input.setAttribute('value', a);
      // input.select()
      // if (document.execCommand('copy')) {
      //   document.execCommand('copy')
      // }
      // document.body.removeChild(input)
      // 动态创建 textarea 标签
      const textarea = document.createElement("textarea");
      // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
      textarea.readOnly = "readonly";
      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";
      // 将要 copy 的值赋给 textarea 标签的 value 属性
      textarea.value = a;
      // 将 textarea 插入到 body 中
      document.body.appendChild(textarea);
      // 选中值并复制
      textarea.select();
      textarea.setSelectionRange(0, textarea.value.length);
      document.execCommand("Copy");
      document.body.removeChild(textarea);
    },
    choose() {
      if (this.SetCard.type === "1") {
        this.Type = "次数";
      } else if (this.SetCard.type === "4") {
        this.Type = "余额(单位/分)";
      } else {
        this.Type = "天数";
      }
    },
    SetGenerateCamilo() {
      this.dialogVisible7 = true;
    },
    sureCard() {
      GenerateCamilo({
        Type: this.SetCard.type,
        CardNum: this.SetCard.cardNum,
        Num: this.SetCard.num,
      }).then((res) => {
        this.allKey = res.Data;
        this.showCopy = true;
        if (res.Code === 2000) {
          this.showCopy = true;
          this.$message({
            message: "生成成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.Message,
            type: "error",
          });
        }
      });
    },
  },
  created() {
    //  this.GetAllWorksForTeacherFn();
    this.GetAllClassByTeacherFn();
    this.GetAllStoreFn();
    this.GetAllTeachPlanFn();

    let arr = [];
    let arr2 = [];
    for (let i = 0; i < 7; i++) {
      arr.push({
        Id: i,
        Name: i + "岁",
      });
    }
    for (let i = 1; i < 13; i++) {
      arr2.push({
        Id: i,
        Name: i + "月",
      });
    }
    this.YearData = arr;
    this.MonthData = arr2;
  },
};
</script>

<style scoped></style>
